/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

/* COMPONENTS */
import Pagina from '../components/Pagina'
import Locator from '../components/Locator/Locator'

const Lojas = ({ data }) => {
  const pageData = { lojas: data.lojas.nodes }

  return (
    <>
      <Pagina pagina={data.pagina} />
      <Locator lojas={pageData.lojas} />
    </>
  )
}

export default Lojas

export const query = graphql`
{
  pagina: contentfulPagina(path: { eq: "/lojas/" }) {
    path
    metaTitle
    metaDescription
    tags
  }
  lojas: allCloudOfertasLoja {
    nodes {
      id
      slug
      address
      cep
      city
      district
      lat
      lng
      name
      reference
      status
      uf
      whatsapp
      services {
        icon
        name
      }
      fridayClose
      fridayIsOpen
    }
  }
} 
`
