/* eslint-disable react/prop-types */
import React, { Component } from 'react'
// import InfiniteScroll from 'react-infinite-scroll-component'
import TagManager from 'react-gtm-module'

import Logo from '../../assets/icons/all/pin-pagina-loja.png'

import './BoxLateral.css'

export default class BoxLateral extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ErrorLocation: [],
      lojas: props.lojas,
      itemsToShow: 100,
      hasMoreData: true,
      center: { lat: -23.549982, lng: -46.634907 },
      inputPesquisa: ''
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  componentDidMount () {
    this.buscaLocation()
  }

  componentDidUpdate () {
    this.encontrarLoja(this.props.marcador)
  }

  fetchMoreData () {
    this.setState(state => {
      const itemsToShow = state.itemsToShow += 10
      return {
        itemsToShow
      }
    })
  }

  getCurrentAddress (lat, lng) {
    this.setState({ center: { lat: lat, lng: lng } })
  }

  calculaDistancia (p) {
    const myLat = p.lat
    const myLng = p.lng
    const lojas = this.props.lojas.map(loja => {
      const LatLoja = loja.lat
      const LngLoja = loja.lng
      const radlat1 = (Math.PI * myLat) / 180
      const radlat2 = (Math.PI * LatLoja) / 180

      const theta = myLng - LngLoja
      const radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      dist = dist * 1.609344
      loja.distance = parseFloat(dist.toFixed(1))
      return loja
    })
    this.setState({
      lojas: lojas.sort(function (a, b) {
        return a.distance - b.distance
      })
    })
  }

  selecionaLoja (idLoja, nomeLoja) {
    if (nomeLoja !== undefined) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'lojasBuscar',
          eventAction: 'buscar-autocomplete',
          eventLabel: nomeLoja
        }
      }
      TagManager.dataLayer(tagManagerArgs)
    }

    const tagManagerArgs = {
      dataLayer: {
        event: 'lojasPopup',
        eventAction: 'detalhes',
        eventLabel: idLoja
      }
    }

    TagManager.dataLayer(tagManagerArgs)

    this.props.atualizaMarcador(idLoja, this.props.lojas)
    this.setState({ idLojaSelected: idLoja })
  }

  buscaLocation (idLoja) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        const tagManagerArgs = {
          dataLayer: {
            event: 'lojasBuscar',
            eventAction: 'buscar-localizacao-auto',
            eventLabel: pos
          }
        }
        TagManager.dataLayer(tagManagerArgs)
        this.props.criaMarcador(pos)
        this.calculaDistancia(pos)
        this.selecionaLoja(this.state.lojas[0].id, this.state.lojas[0].name)
      }, this.errorOnLocation)
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }

  buscaEndereco (location) {
    event.preventDefault()
    let tagManagerArgs

    let cep = location.match(/^([\d]{8}|[\d]{5}-?[\d]{3})$/g)
    if (cep) {
      cep = cep.toString().replace('-', '')
      tagManagerArgs = {
        dataLayer: {
          event: 'lojasBuscar',
          eventAction: 'buscar-cep',
          eventLabel: cep
        }
      }
      TagManager.dataLayer(tagManagerArgs)
    } else {
      tagManagerArgs = {
        dataLayer: {
          event: 'lojasBuscar',
          eventAction: 'buscar-endereco',
          eventLabel: location
        }
      }
      TagManager.dataLayer(tagManagerArgs)
    }

    const { geocoder } = this.props
    geocoder.geocode({
      address: location,
      region: 'br',
      componentRestrictions: {
        country: 'BR'
      }
    },
    (results, status) => {
      console.log('google results', { results: results, status: status })

      if (status === 'OK') {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        }
        this.props.criaMarcador(pos)
        this.calculaDistancia(pos)
        this.selecionaLoja(this.state.lojas[0].id)
      } else {
        console.log('FAIL')
      }
    }
    )
  }

  encontrarLoja (marker) {
    const { lojas } = this.state
    const scroll = document.getElementById('scroll-infinity-box')
    const alinhamento = document.getElementById('mapa')
    const ativo = document.getElementsByClassName('item-lista-lojas-active item-lista-lojas')[0]

    if (lojas !== undefined) {
      lojas.map(loja => {
        return setTimeout(() => {
          if (marker.id === loja.id) {
            const idBoxList = document.getElementById(`${loja.id}`)
            if (idBoxList !== null) {
              idBoxList.scrollIntoView()
              alinhamento.scrollIntoView()
              ativo !== undefined && (ativo.className = 'item-lista-lojas')
              idBoxList.className = 'item-lista-lojas-active item-lista-lojas'
            } else {
              scroll.scrollTo(0, scroll.scrollHeight)
            }
          }
        }, 0)
      })
    }
  }

  handleChange (event) {
    this.setState({ inputPesquisa: event })
  }

  render () {
    const { lojas } = this.state

    return (
      <div id="over-map">
        <section id="box-lateral-esquerda">
          <div className="cd-lc-form-content-question">
            <label id="title" >
              <img src={ Logo } className="pin-lojas" alt="Ícone de marcador com a logo da Novafarma"/> Localize a loja de sua preferência
            </label>
            <form onSubmit={() => this.buscaEndereco(this.state.inputPesquisa)} className="cd-lc-busca-endereco-form" autoComplete="off" acceptCharset="UTF-8">
              <div className="cd-lc-input-group">
                <input
                  type="text"
                  id="address"
                  className="form-control theme-border-2"
                  placeholder="Bairro, Cidade ou CEP"
                  aria-label="Inserir bairro, cidade ou CEP"
                  aria-describedby="Input de texto"
                  value={this.state.inputPesquisa}
                  onChange={() => this.handleChange(event.target.value)} maxLength="80"/>
                <input type="button" name="btn_pesquisar" id="edit-submit" disabled={this.state.inputPesquisa.length < 5} value="ENCONTRAR LOJA DA NOVAFARMA" onClick={() => this.buscaEndereco(this.state.inputPesquisa)} className="mt-3 btn w-100 text-white theme-border-2 font-weight-bold"/>
              </div>
            </form>
            <div className="cd-lc-ou"><p className="cd-lc-datedAside"><span>ou</span></p></div>
            <input type="button" name="local_atual" id="edit-locale" value="USAR MINHA LOCALIZAÇÃO ATUAL" onClick={() => this.buscaLocation()} className="mb-3 btn w-100 text-white theme-border-2 font-weight-bold"/>
          </div>
          <ul className="cd-lc-lista-lojas-box" id="scroll-infinity-box">
            {/* <InfiniteScroll
              dataLength={this.state.itemsToShow}
              next={this.fetchMoreData}
              hasMore={this.state.hasMoreData}
              scrollableTarget="scroll-infinity-box"> */}
            {
              lojas.slice(0, this.state.itemsToShow).map(loja => {
                return (
                  <li
                    key={loja.id}
                    onClick={() => { this.selecionaLoja(loja.id) }}
                    id={loja.id}
                    className={loja.id === this.state.idLojaSelected ? 'item-lista-lojas-active item-lista-lojas' : 'item-lista-lojas'}>
                    <div className="primaryLojasContent">
                      <h3 className="stname">{loja.name}</h3>
                      {
                        loja.distance && <span className="strdistance">Distância: {loja.distance}km</span>
                      }
                      <div className="staddress">
                        {loja.address}
                        <br />
                        {loja.district} - {loja.city}{' '}
                        - {loja.uf} - {loja.cep}
                      </div>
                      <button
                        type="button"
                        name="mais-informacoes"
                        className="botao-mais-informacoes-box">
                        Mais Informações
                      </button>
                    </div>
                  </li>
                )
              })}
            {/* </InfiniteScroll> */}
          </ul>
        </section>
      </div>
    )
  }
}
