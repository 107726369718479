/* eslint-disable react/prop-types */
import { Link } from 'gatsby'
import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import TagManager from 'react-gtm-module'

/* ICONS */
import markerIcon from '../../assets/icons/all/image.png'

/* COMPONENTS */
import BoxLateral from '../../components/Locator/BoxLateral'
import Mobile from './Mobile'

/* STYLES */
import './Locator.css'

export default class Locator extends Component {
  constructor (props) {
    super(props)

    this.state = {
      map: null,
      localizacaoUsuario: '',
      MarcadorClicado: {},
      isMobile: '',
      marcador: undefined
    }
    this.criaMarcador = this.criaMarcador.bind(this)
    this.iniciaMapa = this.iniciaMapa.bind(this)
  }

  componentDidMount () {
    this.setState({ isMobile: (window || { innerWidth: 1280 }).innerWidth <= 765 })
    if (!window.google) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?language=pt-BR&libraries=places&key=${process.env.GATSBY_CREDENTIAL_API_MAPS_LOJAS}`
      const insercao = document.getElementsByTagName('script')[0]
      insercao.parentNode.insertBefore(script, insercao)
      script.addEventListener('load', e => {
        this.iniciaMapa()
      })
    } else {
      this.iniciaMapa()
    }
  }

  errorOnLocation (errorMessage) {
    this.setState({ localizacaoUsuario: errorMessage })
  }

  iniciaMapa () {
    const map = new window.google.maps.Map(document.getElementById('mapa'), {
      center: { lat: -23.549982, lng: -46.634907 },
      zoom: 14,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false
    })
    const lojas = this.criarMarcadoresLojas(map)
    const geocoder = new window.google.maps.Geocoder()

    this.setState({
      map: map,
      geocoder: geocoder,
      lojas: lojas
    })
  }

  setLocation (pos) {
    this.state.map.setCenter(pos)
    this.state.map.setZoom(14)
  }

  criaMarcador (pos) {
    if (this.state.marcador !== undefined) { this.state.marcador.setMap(null) }
    const marcador = new window.google.maps.Marker({
      position: { lat: pos.lat, lng: pos.lng },
      map: this.state.map,
      draggable: false,
      animation: window.google.maps.Animation.DROP
    })
    this.state.map.setCenter(pos)
    this.state.map.setZoom(14)
    console.log('Posição marcada: ', marcador)
    this.setState({
      marcador: marcador
    })
  }

  criarMarcadoresLojas (map) {
    const regex = /[^0-9]+/g
    const lojasmarcador = this.props.lojas.map((loja) => {
      const conteudoInfo = ReactDOMServer.renderToStaticMarkup(
        <div className="infowindow" key={loja.id}>
          <h1 className="titulo-primario-infowindow">{loja.name}</h1>
          <p className="texto-infowindow">{loja.address}</p>
          <p className="texto-infowindow">{loja.district} - {loja.city} {loja.uf}</p>
          <p className="texto-infowindow">{loja.cep}</p>
          <h2 className="titulo-secundario-infowindow"> Serviços </h2>
          <div style={this.state.isMobile ? { display: 'inline-block' } : { display: 'block' }}>
            {loja.services.map(servico => {
              return (
                <img
                  src={servico.icon}
                  alt={`${servico.name} disponível na loja.`}
                  className='mostra-thumbnail-infowindow'
                  key={servico.name}
                  data-toogle="tooltip" title={servico.name} />
              )
            })}
          </div>
          {/* <Buttons metadata={this.props.metadata} slug={loja.Slug} nome={loja} /> */}
          <br/>
          <div className={this.state.isMobile ? 'cd-lc-botoes-info-window' : 'botoes-info-window'}>
            <div className="row">
              <a
                href={`https://www.google.com/maps/dir/Minha+Localização/${loja.lat},${loja.lng}`}
                target="_blank" rel="noopener noreferrer" name={loja.name}
                className="botoes-infowindow botao-como-chegar-infowindow btn-comochegar font-weight-bold">
                COMO CHEGAR
              </a>
              <a
                href={`waze://?ll=${loja.lat},${loja.lng}&navigate=yes`}
                target="_blank" rel="noopener noreferrer" name={loja.name}
                className="botoes-infowindow botao-waze-infowindow btn-waze ml-lg-1 font-weight-bold">
                WAZE
              </a>
            </div>
            <div className="row">
              <Link to={`/lojas/${loja.slug}/contato/`} name={loja.name}
                className="botoes-infowindow botao-ver-ofertas-infowindow btn-conferirofertas btn-lojam font-weight-bold">
                LOJA
              </Link>
              <a
                href={`https://wa.me/55${loja.whatsapp.replace(regex, '')}`}
                target="_blank" rel="noopener noreferrer" name={loja.name}
                className="botoes-infowindow botao-whatsappm-infowindow btn-whatsappm ml-lg-1 font-weight-bold">
                DISK ENTREGA
              </a>
            </div>
          </div>
        </div>
      )

      const infowindow = new window.google.maps.InfoWindow({
        content: conteudoInfo
      })

      window.google.maps.event.addListener(infowindow, 'domready', function () {
        const comoChegar = document.querySelector('.botoes-infowindow, .botao-como-chegar-infowindow, .btn-comochegar')
        comoChegar
          .addEventListener('click', (event) => {
            const tagManagerArgs = {
              dataLayer: {
                event: 'lojasPopup',
                eventAction: 'como-chegar',
                eventLabel: event.target.name
              }
            }
            TagManager.dataLayer(tagManagerArgs)
          })

        const verOfertas = document.querySelector('.botao-ver-ofertas-infowindow, .btn-conferirofertas, .btn-lojam')
        verOfertas
          .addEventListener('click', (event) => {
            const tagManagerArgs = {
              dataLayer: {
                event: 'lojasPopup',
                eventAction: 'detalhes',
                eventLabel: event.target.name
              }
            }
            TagManager.dataLayer(tagManagerArgs)
          })
        const ligar = document.querySelector('.botao-whatsappm-infowindow, .btn-whatsappm')
        ligar
          .addEventListener('click', (event) => {
            // eslint-disable-next-line no-unused-vars
            const tagManagerArgs = {
              dataLayer: {
                event: 'ligar',
                eventAction: 'ligar',
                eventLabel: event.target.name
              }
            }
            TagManager.dataLayer(tagManagerArgs)
          })
      })

      const icon = {
        url: markerIcon, // url
        scaledSize: new window.google.maps.Size(40, 40) // scaled size
      }
      const marcador = new window.google.maps.Marker({
        position: { lat: parseFloat(loja.lat), lng: parseFloat(loja.lng) },
        map: map,
        title: loja.name,
        icon: icon,
        id: loja.id
      })

      marcador.addListener('click', () => {
        const posicaoCentro = {
          lat: marcador.getPosition().lat(),
          lng: marcador.getPosition().lng() - 0.015
        }
        this.setState({ MarcadorClicado: marcador })
        const fechaInfoWindows = document.getElementsByClassName('gm-ui-hover-effect')[0]
        if (fechaInfoWindows) fechaInfoWindows.click()
        setTimeout(() => {
          this.state.map.panTo(posicaoCentro)
          infowindow.open(this.state.map, marcador)
        }, 1250)
      })

      map.addListener('click', () => {
        infowindow.close()
      })

      loja.marcador = marcador
      console.log(loja)
      return loja
    })
    return lojasmarcador
  }

  atualizaMarcador (idLoja, lojas) {
    console.log(idLoja, lojas)
    const lojaAtual = lojas.find(loja => loja.id === idLoja)
    window.google.maps.event.trigger(lojaAtual.marcador, 'click')
  }

  render () {
    console.log(this.props, this.state)
    return (
      <div style={{ position: 'relative' }}>
        <div id="mapa" style={{ width: '100%', height: '95vh' }} />
        {
          !this.state.isMobile && this.state.lojas && this.state.map && <BoxLateral
            atualizaMarcador={this.atualizaMarcador}
            lojas={this.state.lojas}
            map={this.state.map}
            geocoder={this.state.geocoder}
            marcador={this.state.MarcadorClicado}
            criaMarcador={this.criaMarcador}
          />
        }
        {
          this.state.isMobile && this.state.lojas && this.state.map && <Mobile
            atualizaMarcador={this.atualizaMarcador}
            lojas={this.state.lojas}
            map={this.state.map}
            geocoder={this.state.geocoder}
            criaMarcador={this.criaMarcador}
          />
        }
      </div>
    )
  }
}
